import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/admin/bukti-bayar',
        component: () => import('@/layout/ExampleLayout.vue'),
        redirect: '/admin/bukti-bayar/menunggu-pembayaran',
        children: [
            {
                path: 'menunggu-pembayaran',
                name: 'WaitingPaymentView',
                component: () => import('@/views/WaitingPaymentView.vue'),
            },
            {
                path: 'sudah-dibayar',
                name: 'AlreadyPaidView',
                component: () => import('@/views/AlreadyPaidView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
